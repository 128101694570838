











































































import { Component, Vue, Watch } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, sameAs, minLength, maxLength } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import router from "@/router";

const PasswordModule = namespace("password");

@Component({
  components: {
    Validation,
  },
})
export default class ResetPassword extends Vue {
  public name = "ResetPassword";

  @PasswordModule.Action("setUserPassword")
  public setUserPassword: any;

  @PasswordModule.Getter("getSuccess")
  public passwordSuccess: any;

  @PasswordModule.Getter("getDataItem")
  public passwordChanged: any;

  public password = "";
  public confirmPassword = "";
  public token: any = "";
  public submit = true;

  public mounted() {
    this.token = this.$route.query.passwordToken;
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
      },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    };
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const options = {
      passwordToken: this.token,
      rawPassword: this.password,
    };
    this.setUserPassword(options);
  }

  public onUserRedirect() {
    router.push({ name: "Login" });
  }

  @Watch("passwordSuccess")
  public onPasswordResetSuccess(success: any) {
    if (success) {
      this.$toasted.success("Passwort erfolgreich zurückgesetzt!");
      this.submit = false;
    }
  }
}
